import React, { useEffect, useState, useMemo } from "react";
import './style.css';
import Translator from "../../scripts/translator/Translator";
import UseTranslator from "../../scripts/translator/Translator";

import BG1 from '../../assets/media/aboutus1.jpg';
import BG2 from '../../assets/media/aboutus2.jpg';
import BG3 from '../../assets/media/aboutus3.jpg';
import BG4 from '../../assets/media/aboutus4.jpg';

const images = [BG1, BG2, BG3, BG4];

export default function Index(props) {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Get the HTML content for the description
    const descriptionHtml = useMemo(() => UseTranslator({ path: "section.aboutUs.description" }), []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 2500);

        return () => clearInterval(interval);
    }, []);

    return (
        <section {...props} className="about-us-section">
            <div className="container">
                <div className="header text-center">
                    <h2 className="title">
                        <Translator path="section.aboutUs.title" />
                    </h2>
                </div>
                <div className="content">
                    <div className="images">
                        <div className="image-wrapper">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`About Us ${index}`}
                                    className={`image-main ${currentIndex === index ? 'visible' : 'hidden'} ${currentIndex === (index - 1 + images.length) % images.length ? 'rotate' : ''}`}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="description card-white h6" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                </div>
            </div>
        </section>
    );
}
