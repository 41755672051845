import ro_RO from './languages/ro_RO.json';

import config from '../../config.json';

function useTranslator(props) {

    const getTranslatedText = () => {
        switch (config.locale) {
            default: return ro_RO[props.path];
        }
    }

    const startYear = 1994;
    const date = new Date();

    let text = getTranslatedText();
    if (!(text instanceof Array) || !(text instanceof Object)) {
        text = text
            .replaceAll("{yearDifference}", (date.getFullYear() - startYear))
            .replaceAll("{currentYear}", date.getFullYear())
        ;
    }

    return text;
}

export default useTranslator;