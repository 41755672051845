import React, {useEffect, useState, useCallback} from "react";
import {isMobile} from 'react-device-detect';
import Home from "./entities/home";
import AboutUs from "./entities/about-us";
import Services from "./entities/services";
import MetalFabrications from "./entities/metal-fabrications";
import ShopRent from './entities/shop-rent';
import {getRandomNumber} from "./scripts/global";
import config from './config.json';
import bg1 from './assets/media/bg1.jpg';
import bg2 from './assets/media/bg2.jpg';
import bg3 from './assets/media/bg3.jpg';
import bg4 from './assets/media/bg4.jpg';
import Loading from "./loading";
import Maintenance from "./maintenance";
import Footer from "./footer";
import {APIProvider} from "@vis.gl/react-google-maps";

const backgroundImages = [bg1, bg2, bg3, bg4];

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [isMaintenance, setMaintenance] = useState(false);

    const getRandomBackgroundImage = useCallback(() => {
        const randomIndex = getRandomNumber(0, backgroundImages.length - 1);
        return `url(${backgroundImages[randomIndex]})`;
    }, []);

    useEffect(() => {
        setMaintenance(config.maintenance.desktop || (isMobile && config.maintenance.mobile));

        const sections = document.querySelectorAll("section");
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("show");
                    updateSelectorMenu(entry.target.id);
                }
            });
        }, {threshold: 0.1});

        sections.forEach((section, index) => {
            observer.observe(section);
            if (index % 2 === 0 && !isMaintenance) {
                section.style.backgroundImage = getRandomBackgroundImage();
            }
        });

        setIsLoading(false);

        return () => sections.forEach(section => observer.unobserve(section));
    }, [isLoading, getRandomBackgroundImage, isMaintenance]);

    const updateSelectorMenu = (id) => {
        const menuButtons = document.querySelectorAll(".menu.main button");
        menuButtons.forEach(menuButton => {
            const selector = menuButton.querySelector(".selector");
            if (!selector) return;

            const isSelected = menuButton.getAttribute("data-anchor") === id;
            selector.classList.toggle("selected", isSelected);
        });
    };

    const toAnchor = (anchor) => {
        window.location.href = `${window.location.pathname}#${anchor}`;
    };

    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLEMAP_API} onLoad={() => console.log('Maps API has loaded.')}>
            {isMaintenance && <Maintenance className="maintenance opacity-100"/>}
            {isLoading && !isMaintenance && <Loading className="loading"/>}
            {!isLoading && !isMaintenance && (
                <React.Fragment>
                    <Home id={"home"} className="home show" toAnchor={toAnchor}/>
                    <AboutUs id={"aboutUs"}/>
                    <Services id={"services"}/>
                    <MetalFabrications id={"metal-fabrications"}/>
                    <ShopRent id={"shop-rent"}/>
                    <Footer id={"contact"}/>
                </React.Fragment>
            )}
        </APIProvider>
    );
}

export default App;
