import React, {useState, useEffect, useCallback, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import sendEmail from "../../scripts/email-sender/Email";
import Translator from "../../scripts/translator/Translator";
import UseTranslator from "../../scripts/translator/Translator";
import placeholderImage from "../../assets/media/unavailable.png";
import contactImage from '../../assets/media/contact.jpg';
import './style.css';
import {getModal} from "../../scripts/global";

const ProductCard = ({product, onClick, isSelected}) => {
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        import(`../../assets/media/${product.image}`)
            .then((module) => setPhoto(module.default))
            .catch((error) => {
                console.error(`Error loading image ${product.image}`, error);
                setPhoto(placeholderImage);
            });
    }, [product.image]);

    const requestOffer = UseTranslator({path: `button.requestOffer`});
    const [isActive, setIsActive] = useState(isSelected);

    const decimals = product.price?.split(",")[1];

    const handleClick = () => {
        setIsActive(!isActive);
        onClick(product.title);
    };

    return (
        <div data-id={product.id} className={`card ${isActive ? 'active' : ''}`}>
            <div className={"tags"}>
                <div
                    className={`tag brand ${!product.brand || product.brand.length === 0 ? "hidden" : ""}`}>{product.brand ?? ''}</div>
            </div>
            <div className={"header"}>
                <img src={photo} alt="Product"/>
            </div>
            <div className={"content"}>
                <div className={"title"}>{product.title}</div>
                <div className={"description"}>{product.description}</div>
                <div className="actions">
                    <div
                        className={"price"}>{decimals ? <>{product.price.split(",")[0]}<sup>,{decimals}</sup> {process.env.REACT_APP_CURRENCY}</> : requestOffer}</div>
                    <button className="purchase-btn clickable" onClick={handleClick}>
                        <span>
                            <i><FontAwesomeIcon icon={faShoppingCart}/></i>
                            {isActive ? UseTranslator({path: `button.productAdded`}) : UseTranslator({path: `button.addToCart`})}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default function Index(props) {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [emailData, setEmailData] = useState({sending: false});
    const [searchInput, setSearchInput] = useState('');

    const formData = useRef();
    const products = UseTranslator({path: `section.shop-rent.list`});
    const categories = UseTranslator({path: `section.shop-rent.categories`});
    const [productsList, setProductsList] = useState(products);

    const handleClick = useCallback((name) => {
        setSelectedProducts((prevSelected) =>
            prevSelected.includes(name)
                ? prevSelected.filter((item) => item !== name)
                : [...prevSelected, name]
        );
    }, []);

    const filterProducts = useCallback((e) => {
        const inputValue = e?.target.value.toLowerCase() ?? '';
        setSearchInput(inputValue);

        if (selectedCategories.length === 0 && inputValue.trim() === '') {
            setProductsList(products);
        } else {
            setProductsList(products.filter((product) =>
                product.title.toLowerCase().includes(inputValue) &&
                (selectedCategories.length === 0 || selectedCategories.some(filter => product.category === filter))
            ));
        }
    }, [products, selectedCategories]);

    const handleCheckboxFilter = useCallback((e) => {
        const isChecked = e.target.checked;
        const value = e.target.value.toLowerCase();

        setSelectedCategories((prevSelected) =>
            !isChecked ? prevSelected.filter((item) => item !== value) : [...prevSelected, value]
        );
    }, []);

    useEffect(() => document.querySelector(".basket")?.addEventListener("click", () => setModalVisible(true)), []);

    useEffect(() => {
        if (!modalVisible) {
            setEmailData({sending: false});
        }
    }, [modalVisible]);

    useEffect(() => {
        const basket = document.querySelector(".basket .tag");
        if (basket) {
            basket.innerText = selectedProducts.length;
        }
    }, [selectedProducts]);

    useEffect(() => {
        filterProducts({target: {value: searchInput}});
    }, [selectedCategories, searchInput, filterProducts]);

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setEmailData({sending: true});
        sendEmail(formData.current, (response) => setEmailData({
            sending: true,
            status: response.status,
            message: response.message
        }));
    };

    const renderModalContent = () => (
        <div className="modal-content">
            <div className="modal-header" style={{backgroundImage: `url(${contactImage})`}}/>
            <div className="modal-body">
                <div className="title"><Translator path={"contact.title"}/></div>
                <div className="description card-white">
                    {!emailData.sending ? (
                        <form ref={formData} onSubmit={handleEmailSubmit}>
                            <div className={"row row-cols-2 gy-3 contact-form"}>
                                <div className={"col"}>
                                    <input type={"text"} name={"client_name"}
                                           placeholder={UseTranslator({path: "contact.placeholder.name"})}/>
                                </div>
                                <div className={"col"}>
                                    <input type={"text"} name={"client_email"}
                                           placeholder={UseTranslator({path: "contact.placeholder.email"})}/>
                                </div>
                                <div className={"col-12"}>
                                    <textarea name={"client_message"}
                                              placeholder={UseTranslator({path: "contact.placeholder.message"})}/>
                                </div>
                                {selectedProducts.length > 0 &&
                                    <div className={"col-12"}>
                                        <Translator path={"section.shop-rent.selectedProducts"}/>
                                        <div className={"row row-cols-2 justify-content-between"}>
                                            {selectedProducts.map((product, index) => <li key={index}>{product}</li>)}
                                        </div>
                                    </div>
                                }
                                <div className={"col-12"}>
                                    <input type={"hidden"} name={"client_selectedPackages"}
                                           defaultValue={selectedProducts.join(", ")}/>
                                    <button type={"submit"}><Translator path={"contact.send"}/></button>
                                </div>
                            </div>
                        </form>
                    ) : emailData.status == null ? (
                        <FontAwesomeIcon id={"loader"} icon={faSpinner}/>
                    ) : emailData.status === 200 ? (
                        <div className={"alert alert-success"}><Translator path={"contact.send.successfully"}/></div>
                    ) : (
                        <div className={"alert alert-danger"}>{emailData.message}</div>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <section {...props}>
            <div className="row row-cols-1 gx-0 justify-content-evenly p-5">
                <div className="col mb-5">
                    <h2 className="title text-center"><Translator path={"section.shop-rent.title"}/></h2>
                </div>
            </div>
            <div className={"row row-cols-2 gx-0 gy-3 justify-content-center p-5"}>
                <div className="col-12 col-xl-4 col-xxl-2">
                    <div className={"flex-container p-0 px-xl-3"}>
                        <div className={"card"}>
                            <h2><Translator path={"section.shop-rent.search"}/></h2>
                            <div className={"form-group"}>
                                <input
                                    type="text"
                                    defaultValue={searchInput}
                                    onKeyUp={filterProducts}
                                    placeholder="Search for products..."
                                />
                            </div>
                        </div>
                        <div className={"card"}>
                            <h2><Translator path={"section.shop-rent.productType"}/></h2>
                            {categories.map((category, index) => (
                                <div className={"form-group"} key={index}>
                                    <input
                                        type={category.type}
                                        onChange={handleCheckboxFilter}
                                        value={category.category}
                                    />
                                    <label className={"form-label ms-2"}>
                                        {category.label}
                                        <span className={"text-secondary ms-2"}>
                                            ({products.filter(product => product.category === category.category).length})
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-8 col-xxl-8">
                    <div className="shop-rent justify-content-center justify-content-lg-start">
                        {productsList.map((product) => (
                            <ProductCard
                                key={product.id}
                                product={product}
                                onClick={handleClick}
                                isSelected={selectedProducts.includes(product.title)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {getModal({isOpen: modalVisible}, setModalVisible, renderModalContent())}
        </section>
    );
}
